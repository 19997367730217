export default {
  methods : {
    computeVpiAverage(vessel, year, month) {
      let pscAverageScore = parseFloat(vessel[year].psc[month].average_score);
      let icbtAverageScore = parseFloat(vessel[year].icbt[month].average_score);
      let iaAverageScore = parseFloat(vessel[year].ia[month].average_score);

      pscAverageScore = Number.isNaN(pscAverageScore) === true ? 0 : pscAverageScore;
      icbtAverageScore = Number.isNaN(icbtAverageScore) === true ? 0 : icbtAverageScore;
      iaAverageScore = Number.isNaN(iaAverageScore) === true ? 0 : iaAverageScore;

      return ((pscAverageScore + icbtAverageScore + iaAverageScore) / 3).toFixed(2);
    },
    formatInput(value) {
      const cleanedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const parts = cleanedValue.split('.');
      if (parts.length > 1) {
        return `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
      return cleanedValue;
    },
    moveCursorToEnd(event) {
      // Set the cursor position to the end of the input field
      this.$nextTick(() => {
        const inputElement = event.target;
        inputElement.selectionStart = inputElement.selectionEnd = inputElement.value.length;
      });
    },
    addValue(icbtValue, valueToAdd) {
      valueToAdd = parseFloat(valueToAdd);
      let floatedValue = parseFloat(icbtValue);

      floatedValue = (floatedValue + valueToAdd).toFixed(2);

      if (floatedValue > 5) {
        floatedValue = '5.00';
      }
      else if (floatedValue < 0) {
        floatedValue = '0.00';
      }

      return floatedValue;
    },
    subtractValue(icbtValue, valueToAdd) {
      valueToAdd = parseFloat(valueToAdd);
      let floatedValue = parseFloat(icbtValue);

      floatedValue = (floatedValue - valueToAdd).toFixed(2);

      if (floatedValue > 5) {
        floatedValue = '5.00';
      }
      else if (floatedValue < 0) {
        floatedValue = '0.00';
      }

      return floatedValue;
    },
    setLimitValue(icbtValue) {
      icbtValue = this.formatInput(icbtValue);
      if (icbtValue.trim() === '') {
        icbtValue = '0';
      }

      if (icbtValue.length === 1 && icbtValue[0] === '.') {
        icbtValue = '0.';
      }

      if (icbtValue.length > 1) {
        if (icbtValue[0] === '0' && icbtValue[1] !== '.') {
          icbtValue = icbtValue[1];
        }
      }

      let decimalPoints = icbtValue.split('.');
      if (decimalPoints.length > 1) {
        if (decimalPoints[1].length > 2) {
          decimalPoints[1] = decimalPoints[1].substring(0, 2);
        }
        if (decimalPoints[1].length > 1) {
          if (decimalPoints[0] !== '') {
            icbtValue = parseInt(icbtValue) + '.' + decimalPoints[1];
          }
        }
      }

      if (parseFloat(icbtValue) > 5) {
        icbtValue = '5.00';
      }
      else if (parseFloat(icbtValue) < 0) {
        icbtValue = '0.00';
      }
      return icbtValue;
    },
  },
}
