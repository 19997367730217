<script>

export default {
  name : 'SorterArrow',
  props: {
    keyInList : {
      type: String,
      default: ''
    },
    arrayListLength:{
      type:Number,
      default: 0,
    },
    currentOrderBy : {
      type : String,
      default : ''
    },
  },
  data() {
    return {
      direction : ''
    }
  },
  methods: {
    setDirection(emitParam) {
      if (emitParam === this.keyInList) {
        if (this.direction === '') {
          this.direction = 'asc';
        } else if (this.direction === 'asc') {
          this.direction = 'desc';
        } else {
          this.direction = '';
        }
        this.$emit('runCallBack', {
          direction : this.direction,
          orderBy   : emitParam
        });
      } else {
        this.direction = '';
      }
    }
  },
  mounted() {
    this.$parent.$on('sorterArrowSetDirection', this.setDirection);
  },
  destroyed() {
    this.$parent.$off('sorterArrowSetDirection');
  }
}
</script>

<template>
    <span v-if="arrayListLength > 0">
      <font-awesome-icon icon="arrow-up"
                         v-if="direction === 'asc'"
                         class="sort-arrow-up"
      />

      <font-awesome-icon icon="arrow-down"
                         v-if="direction === 'desc'"
                         class="sort-arrow-down"
      />
      </span>

</template>

<style scoped>

</style>
