export default {
  data() {
    return {
      loading : false,
      currentOrderBy : '',
    }
  },
  methods:{
    sorterArrowSetDirection(orderBy) {
      if (this.loading === true) {
        return;
      }
      this.currentOrderBy = orderBy;
      this.$emit('sorterArrowSetDirection', orderBy);
    },
  }
}
