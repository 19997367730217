import { mapGetters } from "vuex";

export const PaginationMixin = {

  computed: {

    ...mapGetters([
      'pagination'
    ]),

    paginationPages() {
      let pages = [];
      let currentPage = this.pagination.currentPage;
      let lastPage = this.pagination.lastPage;
      if(lastPage > 1) {
        let initialLimit = 1;
        if(currentPage + 5 > lastPage) {
          initialLimit = lastPage - 10;
        } else if(currentPage > 6) {
          initialLimit = currentPage - 5
        }
        initialLimit = initialLimit < 1 ? 1 : initialLimit;
        for (let i=initialLimit; i<=initialLimit + 10; i++) {
          pages.push(i);
          if(i>=lastPage) {
            break;
          }
        }
      }
      return  pages;
    }

  }

}
