<template>
  <div class="text-center">
    <div class="row no-gutters">
      <div class="col-12">
        <h3 class="text-left ml-4 align-middle">VPI FOR {{ vpiFilter.year }} (Vessel Performance Indicator)</h3>
      </div>
      <div class="col-12">
        <h6 class="text-left ml-4 align-middle"><i class="updated-score">The blue highlighted areas are the ones that have been updated from the previous month's scores.</i></h6>
      </div>
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1 || loading }">
              <button class="page-link" @click="setVesselsVpiToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...' || loading, 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setVesselsVpiToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage || loading}">
              <button class="page-link" @click="setVesselsVpiToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-auto">
        <label class="col-form-label font-weight-bold">Filter By Year: </label>
      </div>
      <div class="col-md-auto px-1">
        <select name="month" id="month" class="form-control form-control-sm" v-model="vpiFilter.year" :disabled="loading">
          <option :value="year" v-for="year in years">{{year}}</option>
        </select>
      </div>
      <div class="col-3 pb-2" v-if="user.is_vessel === false">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="vpiFilter.search" placeholder="Search Vessel" :disabled="loading">
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="d-flex" style="height: auto; overflow-y: auto; overflow-x: hidden">
          <div class="col-5 p-0 m-0" style="overflow-x: hidden; overflow-y: hidden">
            <table class="small e-tbl">
              <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
              <tr>
                <th rowspan="2" class="align-middle" style="width: 1em;" scope="col">#</th>
                <th style="width: 300px !important;" rowspan="2" class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('name')">
                  SHIP NAME
                  <sorter-arrow
                    :keyInList="'name'"
                    :array-list-length="vessels_vpi.length"
                    :current-order-by="currentOrderBy"
                    @runCallBack="runCallBack"
                  >
                  </sorter-arrow>
                </th>
                <th rowspan="2" class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('flag_id')">
                  FLAG
                  <sorter-arrow
                    :keyInList="'flag_id'"
                    :array-list-length="vessels_vpi.length"
                    :current-order-by="currentOrderBy"
                    @runCallBack="runCallBack"
                  >
                  </sorter-arrow>
                </th>
                <th rowspan="2" class="align-middle no-select cursor-pointer p-0" scope="col" @click="sorterArrowSetDirection('management_office_id')">
                  <div style="height: 57px; justify-content: center; display: flex; align-items: center; width: 99.5%;" :style="{borderRight : loading === true ? '1px solid black' : 'none'}">
                    BRANCH OFFICE
                    <sorter-arrow
                      :keyInList="'management_office_id'"
                      :array-list-length="vessels_vpi.length"
                      :current-order-by="currentOrderBy"
                      @runCallBack="runCallBack"
                    >
                    </sorter-arrow>
                  </div>

                </th>
                </tr>
              <tr>

              </tr>
              </thead>
              <tbody>

              <tr v-if="loading">
                <td colspan="100%" style="border-right: none !important;">
                  <app-loading style="visibility: hidden"></app-loading>
                </td>
              </tr>

              <template v-else>
                <tr v-for="(vessel, vessel_index) in vessels_vpi" :key="vessel.id" class="ia-vessel-list" :title="vessel.name" @mouseover="highlightRow(vessel_index, true)" @mouseout="highlightRow(vessel_index, false)">
                  <td :class="'row_' + vessel_index" class="align-middle">{{ vessel_index + pagination.from }}</td>
                  <td :class="'row_' + vessel_index" class="align-middle text-uppercase">{{ vessel.name }}</td>
                  <td :class="'row_' + vessel_index" class="align-middle">{{ vessel.flag ? vessel.flag : '' }}</td>
                  <td :class="'row_' + vessel_index" class="align-middle">
                    <div v-if="vessel.management_office_name" style="justify-content: center; display: flex; align-items: center; border-right: 1px solid black">
                      {{ vessel.management_office_name ? vessel.management_office_name : '' }}
                    </div>
                    <div v-else style="justify-content: center; display: flex; align-items: center; border-right: 1px solid black">
                      &nbsp;
                    </div>
                  </td>
                </tr>
              </template>

              </tbody>
            </table>
          </div>


          <div class="col-7 p-0 m-0" style="overflow-y: hidden" :style="{overflowX : loading === true ? 'hidden' : 'auto'}">
            <table class="small e-tbl">
              <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
              <tr>
                <th colspan="4">JANUARY</th>
                <th colspan="4">FEBRUARY</th>
                <th colspan="4">MARCH</th>
                <th colspan="4">APRIL</th>
                <th colspan="4">MAY</th>
                <th colspan="4">JUNE</th>
                <th colspan="4">JULY</th>
                <th colspan="4">AUGUST</th>
                <th colspan="4">SEPTEMBER</th>
                <th colspan="4">OCTOBER</th>
                <th colspan="4">NOVEMBER</th>
                <th colspan="4">DECEMBER</th>
                <th>{{ vpiFilter.year }}</th>
              </tr>
              <tr>
                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>PSC</th>
                <th>ICBT</th>
                <th>IA</th>
                <th>AVE</th>

                <th>VPI FOR THE YEAR</th>
              </tr>
              <tr>
                <template v-for="(column, index) in columns">
                  <th class="p-0">
                    <div v-if="index === 48" style="width: 150px"></div>
                    <div v-else></div>
                  </th>
                </template>
              </tr>
              </thead>
              <tbody>

              <tr v-if="loading">
                <td colspan="100%" style="border-left: none !important;">
                  <app-loading style="margin-right: 1500px"></app-loading>
                </td>
              </tr>

              <template v-else>
                <tr v-for="(vessel, vessel_index) in vessels_vpi" :key="vessel.id" class="ia-vessel-list" :title="vessel.name" @mouseover="highlightRow(vessel_index, true)" @mouseout="highlightRow(vessel_index, false)">
                  <template v-for="(month, month_index) in monthNames">
                    <td :class="[vessel[vpiFilter.year].psc[month.toLowerCase()].inspected === true ? 'updated-score' : '', 'row_' + vessel_index]"
                    >
                      {{ vessel[vpiFilter.year].psc[month.toLowerCase()].average_score }}</td>
                    <td v-if="isAllowed('vpi-status.vpi.edit-icbt')"
                        :class="['row_' + vessel_index, vessel[vpiFilter.year].icbt[month.toLowerCase()].inspected === true ? 'updated-score' : '']" @dblclick="showHideInput(vessel.id, vessel_index, month_index, false)"
                        class="cursor-pointer ">
                      <input type="text"
                             autocomplete="off"
                             v-model="vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score"
                             @input="vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score = setLimitValue(vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score)"
                             @keyup.up="vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score = addValue(vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score, 0.01)"
                             @keyup.down="vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score = subtractValue(vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score, 0.01)"
                             class="form-control form-control-sm w-100"
                             @keydown.enter.stop="handleEnter(vessel.id, vessel_index, month_index, true)"
                             :id="'input' + vessel_index + '_' + month_index"
                             @blur.stop="handleBlur(vessel.id, vessel_index, month_index, true)"
                             :style="{display : vessel[vpiFilter.year].icbt[month.toLowerCase()].editing === true && vessel[vpiFilter.year].icbt[month.toLowerCase()].requesting === false ? 'block' : 'none', fontSize : '10px'}"
                      />
                      <template v-if="vessel[vpiFilter.year].icbt[month.toLowerCase()].requesting === true">
                        <app-loading
                          :spinner-only="true"
                          :height="'12px'"
                          :width="'12px'"
                        />
                      </template>
                      <template v-else-if="vessel[vpiFilter.year].icbt[month.toLowerCase()].editing === false"
                      >
                        {{ vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score }}
                      </template>
                    </td>
                    <td v-else>
                      {{ vessel[vpiFilter.year].icbt[month.toLowerCase()].average_score }}
                    </td>


                    <td :class="['row_' + vessel_index, vessel[vpiFilter.year].ia[month.toLowerCase()].inspected === true ? 'updated-score' : '']">
                      {{ vessel[vpiFilter.year].ia[month.toLowerCase()].average_score }}
                    </td>
                    <td :class="'row_' + vessel_index" style="background-color: #ffffcc">
                      {{ vessel[vpiFilter.year].average[month.toLowerCase()].value }}
                    </td>

                  </template>

                  <td class="ia-vessel-list vpi-for-the-year" :class="'row_' + vessel_index">
                    <div class="w-100">
                      {{ computeOverAllAverageInEachVessel(vessel[vpiFilter.year].average)}}
                    </div>
                  </td>
                </tr>
              </template>

              </tbody>
            </table>
          </div>
        </div>




      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading.vue";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import VpiMixin from "@/mixins/VpiMixin";

import SorterArrow from "@/components/common/SorterArrow.vue";
import SorterArrowMixin from "@/mixins/SorterArrowMixin";

export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin, VpiMixin, SorterArrowMixin],
  components: {AppLoading, SorterArrow},

  name: 'VPI',
  data() {
    return {
      monthNames : [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      vpiFilter : {
        page:1,
        year : new Date().getFullYear(),
        perPage : 40,
        order_by : 'name',
        direction : 'asc',
        search  : '',
        audit_type : 'sms',
        for_list : true
      },
      editingValue : 0,
      columns : new Array(53).fill(''),
      years:Array.from({length:100},(_,i)=>new Date().getFullYear() - i),
      audit_types : ['sms', 'isps', 'cyber'],
      enterPressed : false
    }
  },
  methods: {
    ...mapActions([
      'getVessels',
      'deleteVessel',
      'getVesselsVpi',
      'updateICBT',
    ]),
    // setNewValue(vessel_index, monthKey) {
    //   let icbtValue = this.vessels_vpi[vessel_index][this.vpiFilter.year].icbt[monthKey].average_score;
    //   console.log(this.setLimitValue(icbtValue));
    //   // this.vessels_vpi[vessel_index][this.vpiFilter.year].icbt[monthKey].average_score = this.setLimitValue(icbtValue);
    // },
    highlightRow(vessel_index, add = true) {
      if (add === true) {
        $('.row_' + vessel_index).addClass('list-hover')
      } else {
        $('.row_' + vessel_index).removeClass('list-hover')
      }
    },
    async setVesselsVpiToTable(page=1) {
      this.loading = true;
      this.vpiFilter.page=page;

      let param = Object.assign({}, this.vpiFilter);
      if (this.user.is_vessel === true) {
        param.vessel_id = this.user.vessel_id;
      }

      await this.getVesselsVpi(param);
      this.loading = false;
    },
    runCallBack(param) {
      this.vpiFilter.order_by = param.orderBy;
      this.vpiFilter.direction = param.direction;
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.setVesselsVpiToTable(1);
    },
    async handleBlur(vessel_id, vessel_index, month_index, fromInput) {
      if (this.enterPressed === true) {
        this.enterPressed = false;
        return;
      }
      await this.showHideInput(vessel_id, vessel_index, month_index, fromInput);
    },
    async handleEnter(vessel_id, vessel_index, month_index, fromInput) {
      this.enterPressed = true;
      await this.showHideInput(vessel_id, vessel_index, month_index, fromInput);
    },
    setIcbtRequesting(vesselIcbt, value) {
      this.monthNames.forEach((month) => {
        vesselIcbt[month.toLowerCase()].requesting = value;
      })
    },
    async requestVPIPerVessel(param) {
      const vesselIcbt = this.vessels_vpi[param.vessel_index][this.vpiFilter.year].icbt;
      this.setIcbtRequesting(vesselIcbt, true);
      const vesselVpiScores = await this.getVesselsVpi(param);
      this.vessels_vpi[param.vessel_index][this.vpiFilter.year].icbt = vesselVpiScores[0][this.vpiFilter.year].icbt;
      this.setIcbtRequesting(vesselIcbt, false);
    },
    async showHideInput(vessel_id, vessel_index, month_index, fromInput) {
      const month = this.monthNames[month_index].toLowerCase();
      let editing = this.vessels_vpi[vessel_index][this.vpiFilter.year].icbt[month].editing;

      if (editing === true && fromInput === false) {
        return;
      }

      this.vessels_vpi[vessel_index][this.vpiFilter.year].icbt[month].editing = !editing;
      const newIcbtValue = this.vessels_vpi[vessel_index][this.vpiFilter.year].icbt[month].average_score;

      this.vessels_vpi[vessel_index][this.vpiFilter.year].average[month].value = this.computeVpiAverage(this.vessels_vpi[vessel_index], this.vpiFilter.year, month);

      if (this.vessels_vpi[vessel_index][this.vpiFilter.year].icbt[month].editing === false && newIcbtValue.trim() !== '') {
        // await this.setLimitValue(vessel_index, month.toLowerCase());
        await this.updateICBT({
          vessel_id : vessel_id,
          year : this.vpiFilter.year,
          month : month_index + 1,
          value : newIcbtValue,
          for_list : false
        });

        await this.requestVPIPerVessel({
          vessel_index : vessel_index,
          vessel_id : vessel_id,
          year : this.vpiFilter.year,
          for_list : false
        })
      }
      setTimeout(() => {
        $('#input' + vessel_index + '_' + month_index).focus();
      }, 100);
    },
    computeOverAllAverageInEachVessel(monthsAverage) {
      let sum = 0;
      this.monthNames.forEach(month => {
        const monthKey = month.toLowerCase();
        if (monthsAverage[monthKey].value !== '') {
          sum += parseFloat(monthsAverage[monthKey].value);
        }

      });

      return (sum / this.monthNames.length).toFixed(2);
    }
  },
  computed: {
    ...mapGetters(['pagination',
      'vesselList',
      'vessels_vpi',
    ])
  },
  async created() {
    this.setVesselsVpi = _.debounce(this.setVesselsVpiToTable, 750)
    await this.setVesselsVpiToTable();
  },
  watch: {
    'vpiFilter.search'() {
      this.loading = true;
      this.setVesselsVpi()
    },
    'vpiFilter.year'() {
      this.loading = true;
      this.setVesselsVpi()
    },
    'vpiFilter.audit_type'() {
      this.loading = true;
      this.setVesselsVpi()
    },
  },

}
</script>

<style scoped lang="scss">
table tr td {
  font-size: 10px;
}

.force-align-top {
  display: inline-block;
  vertical-align: top;
}


/* Hide the spin buttons in WebKit browsers (Chrome, Safari, Edge) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spin buttons in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.updated-score {
  background-color: #ccffff;
}

.vpi-for-the-year {
  background-color: #f2ceef;
}

td {
  background-color: white;
  border: 1px solid #e1e1e1 !important;
}

</style>
